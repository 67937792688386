import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"

import brand from "../images/brand.svg"

class Navbar extends Component {

    state = {
        isActive: false,
    }
    
    toggleNav = () => {
        this.setState(prevState => ({
            isActive: !prevState.isActive
        }))
    }

    render() {
        return (
            <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <Link
                    to="/"
                    className="navbar-item"
                    activeClassName="navbar-item"
                    >
                    <img alt="Air Water System" src={brand} align="middle"></img>
                    {this.props.siteTitle}
                    </Link>
                    <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" onClick={this.toggleNav}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
                <div className={ this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
                    <div className="navbar-end">

                        <Link to="/" className="navbar-item" activeClassName="navbar-item"> Accueil </Link>
                        
                        <Link to="/blog" className="navbar-item" activeClassName="navbar-item"> Actualités </Link>
                        
                        <Link to="/shop" className="navbar-item" activeClassName="navbar-item"> Boutique </Link>

                        <div className="navbar-item has-dropdown is-hoverable">

                            <Link to="/services" className="navbar-item" activeClassName="navbar-item"> Nos Services </Link>

                            <div className="navbar-dropdown">
                            <Link to="/plomberie" className="navbar-item">
                                Plomberie
                            </Link>
                            <Link to="/climatisation-lyon" className="navbar-item">
                                Climatisation à Lyon
                            </Link>
                            <Link to="/climatisation" className="navbar-item">
                                Climatisation à Mions
                            </Link>
                            <Link to="/chauffage"  className="navbar-item">
                                Chauffage
                            </Link>
                            <Link to="/adoucisseur" className="navbar-item">
                                Adoucisseur d'eau
                            </Link>
                            <hr className="navbar-divider"></hr>
                            <a className="navbar-item">
                                Catalogue produit
                            </a>
                            </div>
                        </div>

                        <div class="navbar-item">
                            <div class="buttons">
                                <Link to="/contact" activeClassName="button is-success" class="button is-success">
                                    <strong>Contact</strong>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </nav>
        )
    }
}

Navbar.propTypes = {
    siteTitle: PropTypes.string,
}
  
Navbar.defaultProps = {
    siteTitle: ``,
}

export default Navbar