/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, keywords, page }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            url
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: keywords ? keywords.join(",") : site.siteMetadata.keywords.join(","),
        },
        {
          name: "url",
          content: site.siteMetadata.url,
        },
        {
          name: "author",
          content: site.siteMetadata.author,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type" : "LocalBusiness",
          "name" : "Air Water System",
          "image" : "https://airwater-system.com/",
          "telephone" : "06 98 99 96 96",
          "email" : "contact@airwater-system.com",
          "address" : {
            "@type" : "PostalAddress",
            "streetAddress" : "4 Rue Frederic Chopin",
            "addressLocality" : "Mions",
            "addressCountry" : "France",
            "postalCode" : "69780"
          }
        }
      `}</script>
      {page === "plomberie" ? <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Quels sont nos zones d’intervention à Mions et dans les environs ?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Un ballon d'eau chaude défectueux ou des problèmes avec votre mitigeur ? Une chasse d'eau en panne ou une installation de robinetterie obsolète ? Toutes ces situations gênantes réclament l'intervention d'un plombier chauffagiste dans les plus brefs délais. Nos plombiers se déplacent directement chez vous , à Mions et dans les communes proches comme Lyon, Grenoble ou Chassieu."
              }
            },
            {
              "@type": "Question",
              "name": "Quels sont les conseils de vos spécialistes de la plomberie ?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Nos artisans vous prodiguent de précieux conseils pour votre installation de chauffage et vos équipements sanitaires. Les spécialistes de la société Air Water System possèdent tout le matériel nécessaire pour effectuer l'entretien et les réparations qui s'imposent. Si vous remarquez que l'eau ne s'évacue plus assez vite dans le siphon de la baignoire ou du lavabo, cela signifie que des saletés se sont accumulées dans les canalisations et commencent à boucher la tuyauterie."
              }
            },
            {
              "@type": "Question",
              "name": "Quels sont les services de proximité de votre plombier à Mions ?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Vous êtes à la recherche d’un artisan plombier pour installer ou changer les sanitaires de votre maison ? Votre système de chauffage est en panne ou vous êtes victime d’une fuite d’eau ? Les plombiers de Air Water System à Mions interviennent pour tous vos travaux de plomberie en neuf ou en rénovation. Combinant savoir-faire et professionnalisme, nos plombiers chauffagistes vous garantissent un service rapide pour tous vos dépannages urgents. Vous avez besoin de rafraîchir votre salle de bain ? Nous proposons aussi l'installation et l'aménagement de votre robinetterie, vasque, douche et baignoire."
              }
            }
          ]
        }
      `}</script> : null}
      {page === "climatisation" ? <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Quelles sont nos installation de systèmes de climatisation à Mions ?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Pour une pose d'appareils de climatisation effectuée dans des conditions optimales, vous pouvez confier votre projet à un spécialiste de la société Air Water System. Si votre climatiseur fixe ne souffle plus d'air froid ou que le débit d'air est trop faible, l'air ambiant ne sera pas correctement renouvelé dans votre foyer et la qualité de l'air sera dégradée. Si de la condensation s'installe dans votre intérieur, c'est que l'humidité de votre habitat n'est pas évacuée."
              }
            },
            {
              "@type": "Question",
              "name": "Pourquoi faire un entretien et une maintenance de climatisation à Mions ?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "La poussière et les saletés sur les filtres vont empêcher le bon passage de l'air. Des branches et des feuilles d'arbre peuvent également recouvrir la grille de l'unité extérieure et gêner l'aspiration et l'évacuation de l'air. Par conséquent, votre consommation énergétique va augmenter et le montant de votre facture d'électricité aussi. Vous pouvez confier la maintenance et l'entretien de vos appareils à notre entreprise pour retrouver un foyer agréable et confortable."
              }
            },
            {
              "@type": "Question",
              "name": "Quels conseils prodigués par des professionnels de la climatisation à Mions ?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "'expert analyse votre projet dans les moindres détails et vous explique quel climatiseur convient le mieux à votre foyer : climatiseur mobile, réversible, mural, chauffage thermodynamique fonctionnant avec une pompe à chaleur... Un climatiseur opérationnel va optimiser la qualité de l'air dans votre habitat. Les chaleurs étouffantes dus aux étés caniculaires et les nuits d'insomnie ne seront bientôt plus qu'un mauvais souvenir."
              }
            }
          ]
        }
      `}</script> : null}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
